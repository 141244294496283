@import '../../styles/base.scss';
:local {
  .link {
    padding: 8px 8px 8px 16px;
    background-color: $corporate-blue-transparent;
    border-radius: 56px;
    display: flex;
    width: fit-content;
    align-items: center;
    margin: 0;
    color: $corporate-blue;
    font-weight: inherit;
  }

  .link:first-of-type {
    margin-top: 33px;
  }

  .iconWrapper {
    width: 24px;
    height: 24px;
    border-radius: 56px;
    margin-left: 16px;
    background-color: white;
    @include flex();

    svg {
      color: $corporate-blue;
    }
  }
}